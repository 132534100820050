<template>
    <div class="wrapper">
      <div class="main-content">
        <section>
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <h1>Contact Us</h1>
                <form @submit="submitForm">
                  <div class="form-group">
                    <label for="first-name">First Name</label>
                    <input type="text" id="first-name" v-model="firstName" class="form-control" required>
                  </div>
                  <div class="form-group">
                    <label for="last-name">Last Name</label>
                    <input type="text" id="last-name" v-model="lastName" class="form-control" required>
                  </div>
                  <div class="form-group">
                    <label for="business-email">Business Email</label>
                    <input type="email" id="business-email" v-model="businessEmail" class="form-control" required>
                  </div>
                  <div class="form-group">
                    <label for="reason">Reason for Contact</label>
                    <textarea id="reason" v-model="reason" class="form-control" required></textarea>
                  </div>
                  <button type="submit" class="btn btn-primary">Submit</button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ContactForm',
    data() {
      return {
        firstName: '',
        lastName: '',
        businessEmail: '',
        reason: ''
      };
    },
    methods: {
      submitForm() {
        // Ajoutez ici la logique de soumission du formulaire
        console.log('Form submitted!');
        console.log('First Name:', this.firstName);
        console.log('Last Name:', this.lastName);
        console.log('Business Email:', this.businessEmail);
        console.log('Reason:', this.reason);
      }
    }
  };
  </script>
  
  <style scoped>
  h1 {
    color: white; /* Couleur du titre en blanc */
  }
  
  label {
    color: white; /* Couleur des libellés en blanc */
  }
  
  input[type="text"],
  input[type="email"],
  textarea {
    background-color: #333; /* Couleur de fond des champs texte */
    color: white; /* Couleur du texte des champs texte en blanc */
  }
  
  button {
    background-color: #007bff; /* Couleur de fond du bouton */
    color: white; /* Couleur du texte du bouton en blanc */
  }
  
  button:hover {
    background-color: #0056b3; /* Couleur de fond du bouton au survol */
  }  

  

  @media (max-width: 912px){
    h1 {
      margin-top: 40px;
      font-size: 20px; /* Taille de police réduite pour les écrans plus petits */
    }
  }
  @media (max-width: 900px) {

    h1 {
      font-size: 24px; /* Taille de police réduite pour les écrans plus petits */
    }
    
    input[type="text"].form-control,
    input[type="email"].form-control,
    textarea.form-control {
      font-size: 14px; /* Taille de police réduite pour les écrans plus petits */
    }
  
    .form-control {
      font-size: 14px !important;
       /* Force la priorité sur d'autres styles */
    }
   
  }
 

</style>